<template>
  <div>
    <BAlert
      :show="totalCard >= maxCard && isUnlimitedCard"
      variant="success"
      class="p-1"
    >
      <div class="flex flex-row items-center justify-between gap-2">
        <div class="flex items-start gap-3 mb-[10px]">
          <img
            class="h-[20px]"
            src="https://storage.googleapis.com/komerce/assets/icons/Checklist-fill.svg"
            alt="check-list"
          >
          <div>
            <h4 class="text-[#626262] font-semibold text-[16px] m-0">
              Berhasil mendapatkan kuota kartu unlimited!
            </h4>
            <div class="text-[#626262] text-[14px] mt-[12px]">
              Terus gunakan kartumu dan lakukan spending sebanyak-banyaknya
            </div>
          </div>
        </div>
        <div class="w-[20px]">
          &nbsp;
        </div>
      </div>
    </BAlert>
    <BAlert
      v-if="isCheckKyc"
      show
      variant="warning"
      class="p-2"
    >
      <div class="flex flex-row items-center justify-between gap-2">
        <div>
          <div class="flex items-center gap-3 mb-[10px]">
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
              alt="Komerce"
            >
            <h4 class="text-black font-semibold m-0">
              {{ titleCheckKyc }}
            </h4>
          </div>
          <BCardText class="text-[#333333]">
            {{ messageCheckKyc }}
          </BCardText>
        </div>
        <BButton
          v-if="
            profile.verification_ktp_status === 0 ||
              profile.verification_ktp_status === 3
          "
          size="sm"
          variant="outline-warning"
          @click="$router.push('/verification-komcard')"
        >
          {{ buttonKycBanner }}
        </BButton>
      </div>
    </BAlert>
    <BAlert
      v-if="warningMessage !== null"
      show
      variant="warning"
      class="p-2"
      dismissible
    >
      <h4 class="text-black font-semibold">
        Informasi Pembuatan Kartu
      </h4>
      <template slot="dismiss">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
          alt="Komerce"
          width="20"
          class="cursor-pointer"
        >
      </template>
      <BCardText class="text-[#333333]">
        {{ warningMessage }}
      </BCardText>
    </BAlert>
    <BCard>
      <BRow class="items-center justify-between pl-[15px] pr-[15px]">
        <div class="flex gap-[12px] items-center">
          <div class="text-black font-semibold m-0 text-2xl">
            Kartu Debit
          </div>
          <div
            class="text-[16px] flex items-center border-[1px] border-[#E2E2E2] rounded-[8px] px-[8px] py-[4px] py px-[12px] py-[8px]"
          >
            <span class="text-[#34A770]">{{ totalCard }}/</span>
            <span v-if="totalCard >= maxCard && isUnlimitedCard !== null">
              <img
                alt="infinity card"
                class="h-[14px]"
                src="https://storage.googleapis.com/komerce/assets/elements/badge-infinite.png"
              ></span>

            <span
              v-show="isUnlimitedCard !== null"
              v-else
            >{{ maxCard }}</span>
          </div>
        </div>
        <BButton
          size="sm"
          :variant="isCheckKyc ? 'secondary' : 'primary'"
          style="display: flex; align-items: center; justify-content: space-between; padding: 5px 10px;"
          :disabled="loading.pin || loading.balance || isCheckKyc"
          @click="checkPin('button-add')"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/card-add.svg"
            alt="Komerce"
            width="20"
          >
          <div class="d-none d-md-block font-semibold ml-[5px]">
            Tambah Kartu
          </div>
          <BSpinner
            v-if="loading.pin"
            variant="light"
            small
            class="ml-1"
          />
        </BButton>
      </BRow>
      <div class="flex justify-between items-center mb-[15px] md:mb-0">
        <div
          class="mt-[12px] sm:flex gap-[10px] sm:items-center w-full sm:w-auto"
        >
          <div class="flex gap gap-[2px] w-full items-center relative">
            <img
              class="absolute h-[20px] ml-[7px]"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
              alt="search"
            >
            <BFormInput
              v-model="search_card"
              debounce="600"
              placeholder="Cari kartu komcards"
              class="pl-3 pr-2"
              @keyup="handleEnterSearch"
            />
            <img
              v-if="search_card !== null"
              class="h-[12px] right-0 absolute mr-[10px] cursor-pointer"
              src="https://storage.googleapis.com/komerce/assets/LP-Komcard/close-circle.svg"
              alt="close"
              @click="resetSearch"
            >
          </div>
          <div
            v-if="search_card && loading.card === false && onSearch"
            class="text-nowrap mt-[10px] sm:mt-[0px]"
          >
            {{ dataCard.length }} data ditemukan
          </div>
        </div>
        <div class="d-none d-md-flex gap-[5px]">
          <button
            :disabled="dataCard.length <= 3"
            :class="dataCard.length <= 3 && 'bg-[#F4F4F4]'"
            class="flex focus:ring-0 focus:outline-none focus:ring-offset-0 justify-center items-center h-[32px] w-[32px] border-[#E2E2E2] border-[1px] rounded-[6px]"
            @click="prevCarousel"
          >
            <img
              class="h-1/2"
              src="https://storage.googleapis.com/komerce/assets/illustration/arrow-left.svg"
              alt="left"
            >
          </button>
          <button
            :disabled="dataCard.length <= 3"
            :class="dataCard.length <= 3 ? 'bg-[#F4F4F4]' : ''"
            class="flex focus:ring-0 focus:outline-none focus:ring-offset-0 justify-center items-center h-[32px] w-[32px] border-[#E2E2E2] border-[1px] rounded-[6px]"
            @click="nextCarousel"
          >
            <img
              class="h-1/2"
              src="https://storage.googleapis.com/komerce/assets/illustration/arrow-right.svg"
              alt="left"
            >
          </button>
        </div>
      </div>
      <!-- START : CAROUSEL -->
      <div>
        <div
          v-if="dataCard.length === 0 && !loading.card"
          body-class="card-dashed"
          class="mb-[10px]"
        >
          <BOverlay
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <div
              class="flex flex-column mt-[12px]  justify-center items-center w-[290px]  sm:w-[350px] h-[250px] p-[20px] border-[1px] border-[#E2E2E2] rounded-[8px] border-dashed"
              :class="totalCard === 0 && 'hover:bg-[#F8F8F8] cursor-pointer'"
              @click="totalCard === 0 && checkPin('banner-empty-card')"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/svg/empty-card.svg"
                alt="Komerce"
                class="m-auto"
              >
              <div class="mt-2 text-[#626262]">
                <span v-if="totalCard > 0">Tidak Ada Kartu</span>
                <div
                  v-else
                  class="text-center"
                >
                  Kamu belum memiliki kartu. Yuk, buat kartu pertama kamu!
                </div>
              </div>
            </div>
          </BOverlay>
        </div>
      </div>
      <div class="sm:mt-[15px] lg:mt-0">
        <BOverlay
          spinner-variant="primary"
          :show="loading.card"
        >
          <VueSlickCarousel
            v-if="dataCard.length > 0"
            ref="carousel"
            :key="carouselKey"
            unslick
            :initial-slide="initialSlide"
            v-bind="settings"
            @afterChange="handleBeforeChange"
          >
            <NewCC
              v-for="(data, key) in dataCard"
              :id="data.id"
              :key="key"
              class="px-[5px]"
              :balance="data.balance"
              :card_number="data.card_number"
              :label="data.label"
              :status="data.status"
              :handle-transfer="handleTransfer"
              :handle-action-topup="handleTopup"
            />
          </VueSlickCarousel>
        </BOverlay>
      </div>
      <!-- END : CAROUSEL -->
      <div class="flex d-flex justify-center d-md-none gap-[5px]">
        <button
          :disabled="dataCard.length <= (windowWidth > 480 ? 2 : 1)"
          :class="
            dataCard.length <= (windowWidth > 480 ? 2 : 1) && 'bg-[#F4F4F4]'
          "
          class="flex focus:ring-0 focus:outline-none focus:ring-offset-0 outline-none justify-center items-center h-[32px] w-[32px] border-[#E2E2E2] border-[1px] rounded-[6px]"
          @click="prevCarousel"
        >
          <img
            class="h-1/2"
            src="https://storage.googleapis.com/komerce/assets/illustration/arrow-left.svg"
            alt="left"
          >
        </button>
        <button
          :disabled="dataCard.length <= 1"
          :class="dataCard.length <= 1 && 'bg-[#F4F4F4]'"
          class="flex focus:ring-0 focus:ring-offset-0 focus:outline-none justify-center items-center h-[32px] w-[32px] border-[#E2E2E2] border-[1px] rounded-[6px]"
          @click="nextCarousel"
        >
          <img
            class="h-1/2"
            src="https://storage.googleapis.com/komerce/assets/illustration/arrow-right.svg"
            alt="left"
          >
        </button>
      </div>
      <hr
        class="border-[1px] mt-[20px] lg:mt-0 border-[#E2E2E2] border-dashed"
      >
      <!-- ketika kurang dari x dan active maka grid-cols-1 -->
      <div
        :class="[isActiveGamification ? 'grid-content-speding-2' : 'grid-content-speding-1',!isVerticalMenuCollapsed ? 'grid-content-speding-force' : '',]"
        class="mt-[30px] gap-[16px] lg:mt-[40px]"
      >
        <div class="h-full">
          <SpendingCategoryVue class="h-full" />
        </div>
        <Gamification
          v-if="isActiveGamification"
          :data-gamification="dataGamification"
          :max-spending-gamification="maxSpendingGamification"
          :leveling-gamification="levelingGamification"
          :spending-gamification="spendingGamification"
          :render-banner="renderBannerGamification"
          :render-progress="renderProgressGamification"
          @claim-gamification="handleClaimGamification"
        />
      </div>
      <hr
        class="border-[1px] mt-[20px] lg:mt-[40px] border-[#E2E2E2] border-dashed"
      >
      <BRow
        class="pl-[15px] pr-[15px] mt-[20px] lg:mt-[40px]  items-center justify-between"
      >
        <div class="text-black font-semibold m-0 text-2xl">
          Mutasi
        </div>
        <div class="flex items-center flex-row">
          <BButton
            style="padding: 5px 1rem"
            variant="outline-primary"
            size="sm"
            @click="$bvModal.show('download-mutation-transaction-komcards')"
          >
            <div class="ml-[10px] mr-[10px] flex items-center">
              <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
              />
              <span class="ml-[4px]">Download Mutasi</span>
            </div>
          </BButton>
          <div class="ml-1">
            <BButton
              id="filter"
              size="sm"
              variant="primary"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                alt="Komerce"
                width="20"
              >
            </BButton>
            <BPopover
              target="filter"
              triggers="click"
              placement="bottomleft"
            >
              <label>Tanggal</label>
              <BRow>
                <BCol md="6">
                  <FlatPickr
                    v-model="startDate"
                    class="form-control"
                    placeholder="Mulai Dari"
                    :config="{
                      mode: 'single',
                      altInput: true,
                      altFormat: 'j/n/Y',
                      dateFormat: 'Y-m-d',
                    }"
                    @input="setStartDate"
                  />
                </BCol>
                <BCol md="6">
                  <FlatPickr
                    v-model="endDate"
                    class="form-control"
                    placeholder="Sampai Dengan"
                    :config="{
                      mode: 'single',
                      altInput: true,
                      altFormat: 'j/n/Y',
                      dateFormat: 'Y-m-d',
                      minDate: startDate,
                    }"
                    @input="setEndDate"
                  />
                </BCol>
              </BRow>
              <label class="mt-1">Jenis Transaksi</label>
              <VSelect
                v-model="transactionType"
                :options="optionsTransactionType"
                :reduce="option => option.value"
                label="name"
                @input="setTransactionType"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <label class="mt-1">Nama Kartu</label>
              <VSelect
                v-model="cardId"
                :options="optionsCardId"
                :reduce="option => option.value"
                label="name"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <BRow class="mx-auto mt-2">
                <BButton
                  variant="outline-primary"
                  class="mr-1"
                  @click.prevent="resetFilter()"
                >
                  Reset
                </BButton>
                <BButton
                  variant="primary"
                  @click.prevent="getListMutation()"
                >
                  Terapkan
                </BButton>
              </BRow>
            </BPopover>
          </div>
        </div>
      </BRow>
      <Mutation
        :scroll="'scroll'"
        :loading="loading.mutation"
        :items="listMutation"
        :fields="fields"
        :status-class="statusClass"
        :nominal-class="nominalClass"
      />

      <ModalCreatePin />
      <ModalInfoBalance
        :balance="balance"
        :min-saldo-kompay="minSaldoKompay"
      />
      <ModalTopup
        :card-data="cardData"
        :get-cards="getListCard"
        :get-mutation="getListMutation"
        :balance-data="balanceData"
      />
      <ModalDownloadMutasi :list-cards="allDataCard" />
      <ModalTransfer
        :id="idModalTransfer"
        :info-balance="balanceDetail"
      />
      <ModalLimitQuota />
      <ModalGamification @refresh="getGamificationData" />
    </BCard>
  </div>
</template>

<script>
import { FULL_DATE_TIME, YEAR_MONTH_DAY } from '@/libs/formatDate'
import size from 'lodash/size'
import moment from 'moment'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import FlatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import VueSlickCarousel from 'vue-slick-carousel'
import { IDR } from '@/libs/currency'
import useVerticalNavMenuGroup from '@/@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-group/useVerticalNavMenuGroup'
import useVerticalNavMenu from '@/@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import { columnConfig, settings, optionsTransactionType } from './config'
import {
  Mutation,
  NewCC,
  ModalCreatePin,
  ModalInfoBalance,
  ModalTransfer,
  ModalTopup,
  ModalDownloadMutasi,
  ModalGamification,
  Gamification,
} from './components'
import ModalLimitQuota from './components/ModalLimitQuota.vue'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'

// import 'vue-select/dist/vue-select.css'
import SpendingCategoryVue from './components/SpendingCategory.vue'

export default {
  components: {
    ModalLimitQuota,
    Mutation,
    Gamification,
    ModalCreatePin,
    ModalInfoBalance,
    ModalTopup,
    ModalGamification,
    FlatPickr,
    VSelect,
    NewCC,
    VueSlickCarousel,
    SpendingCategoryVue,
    ModalDownloadMutasi,
    ModalTransfer,
  },
  data() {
    return {
      IDR,
      FULL_DATE_TIME,
      YEAR_MONTH_DAY,

      // START : carousel state
      stateCarousel: 0,
      firstRenderCards: true,
      initialSlide: 0,
      jumlahSlide: 3, // dynamic by viewport
      settings,
      windowWidth: 0,
      carouselKey: 0,
      onSearch: false,
      // END

      // START : params data cards
      dataCard: [],
      isUnlimitedCard: null,
      limit_card: 10,
      offset_card: 0,
      search_card: null,
      status_card: null,
      // END

      idModalTransfer: null,
      status: null,
      fields: columnConfig,
      listMutation: [],
      showCardDetails: false,
      activeCard: null,
      balanceDetail: {},
      loading: {
        card: false,
        mutation: false,
        balanceDetailForTransfer: false,
        pin: false,
        balance: false,
        getBalanceMonit: false,
      },
      size,
      idPartner: this.$store.state.auth.userData.id,
      balance: null,
      startDate: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      limit: 10,
      offset: 0,
      lastData: false,
      totalCard: null,
      lastDataCard: false,
      transactionType: '',
      optionsTransactionType,
      cardId: '',
      optionsCardId: [],
      allDataCard: [],
      selectedTransactions: [],
      maxCard: 0,
      minCard: 4,
      maxCardMessage: '',
      minCardMessage:
        'Kamu telah membuat 5 kartu komcards. Biaya pembuatan kartu berikutnya adalah Rp. 20.000/kartu dengan saldo awal minimal Rp. 20.000. Periksa saldo kompaymu sebelum melanjutkan proses selanjutnya!',
      warningMessage: null,
      minTopUp: 40000,
      minSaldo: 20000,
      minSaldoKompay: null,
      cardData: {},
      showModalTopup: false,
      balanceData: null,
      profile: {},
      nextItem: false,

      // START : Gamification
      dataGamification: null,
      isActiveGamification: null,
      bannerGamification: 'active',
      spendingGamification: null,
      maxSpendingGamification: 400000000,
      levelingGamification: [
        {
          title: 'Level 1',
          labelCurrentcy: 'Rp100Juta',
          valueMinimum: 100000000,
        },
        {
          title: 'Level 2',
          labelCurrentcy: 'Rp200Juta',
          valueMinimum: 200000000,
        },
        {
          title: 'Level 3',
          labelCurrentcy: 'Rp300Juta',
          valueMinimum: 300000000,
        },
        {
          title: 'Level 4',
          labelCurrentcy: 'Rp400Juta',
          valueMinimum: 400000000,
        },
      ],
      // END
    }
  },
  setup(props) {
    const { isVerticalMenuCollapsed } = useVerticalNavMenu(props.item)

    return {
      isVerticalMenuCollapsed,
    }
  },
  computed: {
    renderBannerGamification() {
      if (
        this.dataGamification.achieved_at === null
        && this.dataGamification.expired_at === null
      ) {
        return 'pending'
      }

      if (
        new Date().getTime()
        > new Date(this.dataGamification.expired_at).getTime()
      ) {
        return 'expired'
      }

      if (
        new Date().getTime()
        < new Date(this.dataGamification.expired_at).getTime()
      ) {
        return 'active'
      }

      return 'pending'
    },
    renderProgressGamification() {
      if (
        this.dataGamification.achieved_at === null
        && this.dataGamification.expired_at === null
      ) {
        return 'active'
      }

      return 'inactive'
    },
    setStartDate() {
      return value => {
        this.startDate = value
      }
    },
    setEndDate() {
      return value => {
        this.endDate = value
      }
    },
    setTransactionType() {
      return value => {
        this.transactionType = value
      }
    },
    statusClass() {
      return status => {
        if (status === 'success') {
          return 'text-[#34A770]'
        }
        if (status === 'failed') {
          return 'text-[#E31A1A]'
        }
        if (status === 'pending') {
          return 'text-[#828282]'
        }
        return ''
      }
    },
    nominalClass() {
      return type => {
        if (type === 'credit') {
          return 'text-[#34A770]'
        }
        if (type === 'debit') {
          return 'text-[#E31A1A]'
        }
        if (type === 'cashback') {
          return 'text-[#CC9212]'
        }
        return ''
      }
    },
    resetFilter() {
      return () => {
        this.startDate = moment()
          .startOf('month')
          .format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
        this.transactionType = ''
        this.cardId = ''
      }
    },
    handleTopup() {
      return card => {
        this.cardData = card
        this.showModalTopup = true
        this.getBalance()
      }
    },
    isCheckKyc() {
      if (this.dataCard.length >= 1) {
        if (
          this.profile.verification_ktp_status === 0
          || this.profile.verification_ktp_status === 1
          || this.profile.verification_ktp_status === 3
        ) {
          return true
        }
      }
      return false
    },
    titleCheckKyc() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Verifikasi KTP'
      }
      if (this.profile.verification_ktp_status === 1) {
        return 'Verifikasi KTP dalam proses persetujuan'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Verifikasi KTP ditolak'
      }
      return null
    },
    messageCheckKyc() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Setelah verifikasi berhasil, kamu juga bisa membuat kartu Komcards lagi'
      }
      if (this.profile.verification_ktp_status === 1) {
        return 'Maaf kamu belum bisa membuat kartu baru karena sedang dalam proses persetujuan admin'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Pengajuan verifikasi KTP ditolak, silahkan ajukan verifikasi ulang untuk bisa membuat kartu baru'
      }
      return null
    },
    buttonKycBanner() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Verifikasi Sekarang'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Verifikasi Ulang'
      }
      return null
    },
  },
  watch: {
    dataCard: {
      handler(newValue, oldValue) {
        const arrayOfIdNew = newValue.map(e => e.id)
        const arrayOfIdOld = oldValue.map(e => e.id)

        const resultfilter = arrayOfIdNew.filter(
          item => !arrayOfIdOld.includes(item),
        )

        // handle jika user langsung ke kartu terakhir
        const resultfilterNull = newValue
          .filter(e => e.balance === undefined || e.balance === null)
          .map(e => e.id)
        if (this.dataCard !== []) {
          if (
            newValue.map(e => e.id).sort() !== oldValue.map(e => e.id).sort()
            && newValue.length > 0
            && resultfilterNull.length > 0
          ) {
            const combinedUnique = [
              ...new Set([...resultfilter, ...resultfilterNull]),
            ]

            this.getBalanceMonit(combinedUnique)
          }
          this.forceRerender()
        }
      },
      deep: true,
    },
    stateCarousel(newValue) {
      if (newValue + 1 === this.dataCard.length) {
        this.getNextDataCards('last')
        this.lastDataCard = true
      }
      if (newValue + this.jumlahSlide === this.dataCard.length) {
        this.getNextDataCards('next')
      }
    },
  },
  created() {
    window.onscroll = () => {
      if (
        window.innerHeight + window.scrollY
          >= document.getElementById('scroll').offsetHeight
        && !this.loading.mutation
      ) {
        this.getNextDataMutation()
      }
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    // Optionally, add a resize event listener for dynamic updates
    window.addEventListener('resize', this.handleResize)
    this.checkUnlimitedCard()
    this.getListCard()
    this.getListMutation()
    this.getProfile()
    this.getAllDataCard()
    this.getGamificationData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    async getGamificationData() {
      const url = '/auth/api/v1/komcard/gamification'

      try {
        const res = await newAxiosIns.request({
          method: 'GET',
          url,
        })

        const { spending } = res.data.data
        this.spendingGamification = spending

        this.dataGamification = res.data.data
        this.isActiveGamification = true
      } catch (error) {
        this.isActiveGamification = false
      }
    },
    handleClaimGamification() {
      this.$bvModal.show('modal-gamification')
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    forceRerender() {
      this.carouselKey += 1
    },
    async getAllDataCard() {
      const url = '/komcards/api/v1/card/list'

      const params = {
        offset: 0,
        // limit is 10000 this get all data
        limit: 10000,
      }

      try {
        const res = await newAxiosIns.get(url, { params })
        this.allDataCard = res.data.data

        const debitCard = [
          {
            value: '',
            name: 'Semua',
          },
          ...this.allDataCard.map(card => ({
            value: card.id,
            name: card.label,
          })),
        ]

        this.$nextTick(() => {
          this.optionsCardId = debitCard
        })
        return
      } catch (error) {
        this.$toast_error({
          message: 'Gagal load data card',
        })
      }
    },
    getNextDataCards(state) {
      let param
      if (this.firstRenderCards) {
        param = this.limit_card
      } else {
        param = 0
      }
      if (!this.lastDataCard) {
        let limit = this.limit_card
        let offset = this.offset_card + param

        if (state === 'last') {
          limit = this.totalCard
          offset = 0
        }

        this.loading.card = true
        const params = {
          search: this.search_card,
          offset,
          limit,
          status: this.status_card,
        }

        const url = '/komcards/api/v1/card/list'

        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { data } = res.data
            const newArrayData = data.map(obj => ({
              ...obj,
              balance: undefined,
            }))

            if (state === 'last') {
              this.dataCard = [...newArrayData]
            } else {
              this.dataCard = [...this.dataCard, ...newArrayData]
            }

            this.offset_card = data.length + this.offset_card + param

            if (data.length < this.limit_card) {
              this.lastDataCard = true
            }
            this.initialSlide = state === 'next' ? this.stateCarousel : this.totalCard - 2

            this.firstRenderCards = false

            this.loading.card = false
          })
          .catch(e => {
            this.loading.card = false
            this.$toast_error({
              message: 'Gagal load data card',
            })
          })
      }
    },
    nextCarousel() {
      this.$refs.carousel.next()
    },
    prevCarousel() {
      this.$refs.carousel.prev()
    },
    handleEnterSearch(e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.onSearch = true
        this.stateCarousel = 0
        this.initialSlide = 0
        this.offset_card = 0

        if (this.search_card && this.search_card !== '') {
          this.getListSearch()
          this.lastDataCard = true
        } else {
          this.lastDataCard = false
          this.getListCard()
        }
      } else {
        this.onSearch = false
      }
    },
    handleBeforeChange(e) {
      this.stateCarousel = e
    },
    resetSearch() {
      this.search_card = null
      this.lastDataCard = false
      this.initialSlide = 0
      this.getListCard()
    },
    async getListSearch() {
      this.loading.card = true

      const params = {
        search: this.search_card,
        offset: 0,
        limit: this.totalCard, // totalCard,
        status: this.status_card,
      }
      const url = '/komcards/api/v1/card/list'
      await newAxiosIns
        .request({ url, method: 'get', params })
        .then(res => {
          const { data, meta } = res.data

          this.maxCard = meta.quota
          this.totalCard = meta.total
          const newArrayData = data.map(obj => ({ ...obj, balance: undefined }))

          this.maxCardMessage = `Batas maksimal Kartu Komcards yang bisa kamu buat adalah ${this.maxCard} kartu dan kamu telah mencapai batas maksimum untuk pembuatan Kartu Komcards.`
          this.$nextTick(() => {
            this.dataCard = newArrayData
          })

          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.$toast_error({
            message: 'Gagal memuat data kartu, silahkan coba lagi',
          })
        })
    },
    async checkUnlimitedCard() {
      // check unlimited card availibity
      const urlCheckUnlimited = '/komcards/api/v1/card/check-unlimited-quota'

      const availibity = await newAxiosIns.get(urlCheckUnlimited)

      this.isUnlimitedCard = availibity.data.data.status
    },
    async getListCard() {
      this.loading.card = true

      const params = {
        search: this.search_card,
        offset: this.offset_card,
        limit: this.limit_card,
        status: this.status_card,
      }
      const url = '/komcards/api/v1/card/list'
      await newAxiosIns
        .request({ url, method: 'get', params })
        .then(res => {
          const { data, meta } = res.data

          this.maxCard = meta.quota
          this.totalCard = meta.total

          this.maxCardMessage = `Batas maksimal Kartu Komcards yang bisa kamu buat adalah ${this.maxCard} kartu dan kamu telah mencapai batas maksimum untuk pembuatan Kartu Komcards.`
          const newArrayData = data.map(obj => ({ ...obj, balance: undefined }))
          this.dataCard = newArrayData

          if (this.totalCard > this.minCard && this.totalCard < this.maxCard) {
            this.warningMessage = this.minCardMessage
          }
          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.$toast_error({
            message: 'Gagal memuat data kartu, silahkan coba lagi',
          })
        })
    },
    async getBalanceMonit(arrayOfIdCard) {
      this.loading.getBalanceMonit = true
      const url = '/komcards/api/v1/card/balance-cards'
      try {
        const res = await newAxiosIns.request({
          method: 'post',
          data: {
            card_ids: arrayOfIdCard,
          },
          url,
        })

        const { data } = res.data

        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < this.dataCard.length; j += 1) {
            if (data[i].id === this.dataCard[j].id) {
              this.dataCard[j].balance = data[i].balance
            }
          }
        }

        this.loading.getBalance = false
      } catch (error) {
        this.loading.getBalance = false
        this.$toast_error({
          message: 'Gagal load data get balance',
        })
      }
    },
    async getListMutation() {
      this.loading.mutation = true
      this.offset = 0
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        transaction_type: this.transactionType,
        card_id: this.cardId,
        offset: this.offset,
        limit: this.limit,
      }
      const url = '/komcards/api/v1/card/mutation'
      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.listMutation = data
          this.loading.mutation = false

          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading.mutation = false
          this.$toast_error({
            message: 'Gagal load data balance, silahkan coba lagi',
          })
        })
    },
    handleTransfer(id) {
      this.balanceDetailForTransfer(id)
      this.idModalTransfer = parseInt(id, 10)
      this.$bvModal.show('modal-transfer')
    },
    async checkPin(event) {
      if (event === 'button-add') {
        this.loading.pin = true
      }

      if (!this.isUnlimitedCard && this.totalCard >= this.maxCard) {
        this.loading.pin = false
        this.$bvModal.show('modal-limit-quota')
        return
      }

      const url = '/v1/pin/check'
      await komshipAxiosIns
        .get(url)
        .then(res => {
          this.loading.pin = false
          const {
            data: { is_set },
          } = res.data

          // eslint-disable-next-line camelcase
          if (is_set === false) {
            this.$bvModal.show('modal-check-pin')
          } else {
            this.checkBalance()
          }
        })
        .catch(() => {
          this.loading.pin = false
          this.$toast_error({ message: 'Gagal check pin, silahkan coba lagi' })
        })
    },

    async balanceDetailForTransfer(id) {
      this.loading.balanceDetailForTransfer = true

      const url = `/komcards/api/v1/card/${id}/info-balance`

      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.loading.balanceDetailForTransfer = false
          const balanceDetail = { ...data, balance: data.monit_balance }
          this.balanceDetail = balanceDetail
        })
        .catch(() => {
          this.loading.balanceDetailForTransfer = false
          this.$toast_error({
            message: 'Gagal memuat data balance, silahkan coba lagi',
          })
        })
    },
    async checkBalance() {
      this.loading.balance = true
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns
        .get(url)
        .then(res => {
          const {
            data: { balance },
          } = res.data
          this.balance = balance
          this.loading.balance = false
          if (balance < this.minSaldo && this.dataCard.length < 5) {
            this.$bvModal.show('modal-info-balance')
            this.minSaldoKompay = this.minSaldo
          } else if (balance < this.minTopUp && this.dataCard.length >= 5) {
            this.$bvModal.show('modal-info-balance')
            this.minSaldoKompay = this.minTopUp
          } else {
            this.$router.push({
              path: '/komcards/add',
              query: { count: this.totalCard },
            })
          }
        })
        .catch(() => {
          this.loading.balance = false
          this.$toast_error({
            message: 'Gagal load data balance, silahkan coba lagi',
          })
        })
    },
    async getBalance() {
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns
        .get(url)
        .then(res => {
          const {
            data: { balance },
          } = res.data
          this.balanceData = balance
          if (this.showModalTopup === true) {
            this.$bvModal.show('modal-topup')
          }
        })
        .catch(() => {
          this.$toast_error({
            message: 'Gagal load data balance, silahkan coba lagi',
          })
        })
    },
    async getProfile() {
      const url = '/v1/my-profile'
      await komshipAxiosIns.post(url).then(res => {
        const { data } = res.data
        this.profile = data
      })
    },
    getNextDataMutation() {
      if (!this.lastData) {
        this.loading.mutation = true
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          transaction_type: this.transactionType,
          card_id: this.cardId,
          offset: this.offset,
          limit: this.limit,
        }
        const url = '/komcards/api/v1/card/mutation'
        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { data } = res.data
            this.loading.mutation = false
            this.$nextTick(() => {
              this.listMutation.push(...data)
            })
            this.offset += data.length
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading.mutation = false
            this.$toast_error({
              message: 'Gagal load data balance, silahkan coba lagi',
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./Komcard.scss" />
